import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import WicketIcon from "../assets/svgs/wicketBall.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { appContext } from "../context/context";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const getOversDataSetOfTeam = (team) => {
  const innings =
    team?.inningDetails && Array.isArray(team.inningDetails)
      ? team.inningDetails[0]
      : {};
  if (!innings) {
    return {};
  }
  const overDataset = innings?.overDataset;

  if (overDataset) {
    return overDataset;
  }
  return {};
};

const getRunsDataSetOfTeam = (team) => {
  const overDataset = getOversDataSetOfTeam(team);
  const runsDataSet = overDataset?.teamRuns;

  if (runsDataSet && Array.isArray(runsDataSet)) {
    return [0, ...runsDataSet];
  }
  return [];
};

const getWicketsDataSetOfTeam = (team) => {
  const overDataset = getOversDataSetOfTeam(team);
  const wicketsDataSet = overDataset?.teamWickets;

  if (wicketsDataSet && Array.isArray(wicketsDataSet)) {
    return wicketsDataSet;
  }
  return [];
};

const getMaxOversOfTeam = (team) => {
  const innings =
    team?.inningDetails && Array.isArray(team.inningDetails)
      ? team.inningDetails[0]
      : {};
  if (innings) {
    return innings?.maxOvers || 0;
  }
  return 0;
};

const createCombinedData = (runs, wickets) => {
  return runs.map((run, index) => {
    return {
      x: index, // Position for x-axis (overs)
      y: run,
      isWicket: wickets.includes(index),
      totalWickets: wickets.filter((over) => over <= index).length,
      wicketsInOver: wickets.filter((over) => over === index).length, // Count of wickets in the same over
    };
  });
};

function BattingGraph() {
  const appState = useContext(appContext);
  const fixture = appState.selectedFixtureDetailed;
  const teamsDetails = fixture?.teamsDetails;

  const teamAName = Array.isArray(teamsDetails)
    ? teamsDetails[0].teamName
    : "Team A";
  const teamBName = Array.isArray(teamsDetails)
    ? teamsDetails[1].teamName
    : "Team B";

  // Ensure that teamsDetails is not null and contains data
  if (!Array.isArray(teamsDetails) || teamsDetails.length < 2) {
    return <div>No data available to display the graph.</div>;
  }

  const teamAMaxOvers = getMaxOversOfTeam(teamsDetails[0]);
  const teamBMaxOvers = getMaxOversOfTeam(teamsDetails[1]);
  const oversCount =
    teamAMaxOvers > teamBMaxOvers ? teamAMaxOvers : teamBMaxOvers;
  const oversArray = Array.from({ length: oversCount + 1 }, (_, i) => i);

  const teamARuns = getRunsDataSetOfTeam(teamsDetails[0]);
  const teamBRuns = getRunsDataSetOfTeam(teamsDetails[1]);
  const teamAWickets = getWicketsDataSetOfTeam(teamsDetails[0]);
  const teamBWickets = getWicketsDataSetOfTeam(teamsDetails[1]);

  // Use the dynamic data directly
  const data = {
    overs: oversArray,
    teamARuns: teamARuns,
    teamBRuns: teamBRuns,
    teamAWickets: teamAWickets,
    teamBWickets: teamBWickets,
  };

  const teamAData = createCombinedData(data.teamARuns, data.teamAWickets);
  const teamBData = createCombinedData(data.teamBRuns, data.teamBWickets);

  const chartData = {
    labels: data.overs,
    datasets: [
      {
        label: teamAName,
        data: teamAData,
        borderColor: "blue",
        showLine: true,
        fill: false,
        tension: 0.4,
        pointRadius: teamAData.map((point) => (point.isWicket ? 5 : 0)),
        pointBackgroundColor: "#FFFFFF",
      },
      {
        label: teamBName,
        data: teamBData,
        borderColor: "green",
        backgroundColor: "rgba(0, 255, 0, 0.1)",
        showLine: true,
        fill: false,
        tension: 0.4,
        pointRadius: teamBData.map((point) => (point.isWicket ? 5 : 0)),
        pointBackgroundColor: "#FFFFFF",
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        border: {
          color: "#FFFFFF", // Color of the x-axis border line
        },
        grid: {
          display: false, // Hide x-axis grid lines
        },
        title: {
          display: true,
          text: "Overs",
          color: "#FFFFFF", // Color of the x-axis title
        },
        ticks: {
          color: "#FFFFFF", // Color of the x-axis ticks
          callback: function (value) {
            return value === 0 ? "Overs" : value; // Replace 0 with "Overs"
          },
          stepSize: oversCount > 50 ? 10 : 5, // Adjust interval based on the format
        },
      },
      y: {
        type: "linear",
        position: "left",
        border: {
          color: "#FFFFFF", // Color of the y-axis border line
        },
        grid: {
          color: "#454E74", // Color of the y-axis grid lines
          display: true, // Display y-axis grid lines
        },
        title: {
          display: false,
          text: "Runs",
          color: "#FFFFFF", // Color of the y-axis title
        },
        ticks: {
          color: "#FFFFFF", // Color of the y-axis ticks
          stepSize: 50, // Default gap of 50 runs between ticks
          callback: function (value) {
            return value === 0 ? "" : value; // Remove 0 from the y-axis
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "#FFFFFF", // Color of the legend labels
        },
        onClick: (e) => {}, // Disable legend click event
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            const dataPoint = context[0].raw;
            return `Over: ${dataPoint.x}`;
          },
          label: function (context) {
            const dataPoint = context.dataset.data[context.dataIndex];
            if (dataPoint.isWicket) {
              return [
                `Runs: ${dataPoint.y}`,
                `Total Wickets: ${dataPoint.totalWickets}`,
              ];
            }
            return `Runs: ${dataPoint.y}`;
          },
          labelTextColor: function () {
            return "#FFFFFF"; // Color of the tooltip text
          },
        },
        backgroundColor: "#333333", // Background color of the tooltip
        titleColor: "#FFFFFF", // Color of the tooltip title
        bodyColor: "#FFFFFF", // Color of the tooltip body
      },
    },
  };

  const wicketImage = new Image();
  wicketImage.src = WicketIcon; // Replace with your wicket image URL

  const customPlugin = {
    id: "customWicketPoints",
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;
      // Draw background color
      ctx.save();
      ctx.fillStyle = "#242A42";
      ctx.fillRect(
        chartArea.left,
        chartArea.top,
        chartArea.right - chartArea.left,
        chartArea.bottom - chartArea.top
      );
      ctx.restore();
    },
    afterDatasetDraw: (chart, args, options) => {
      const ctx = chart.ctx;
      const dataset = chart.getDatasetMeta(args.index).data;
      // Draw the wicket points
      dataset.forEach((point, index) => {
        const dataPoint = chart.data.datasets[args.index].data[index];
        if (dataPoint.isWicket) {
          for (let i = 0; i < dataPoint.wicketsInOver; i++) {
            ctx.drawImage(
              wicketImage,
              point.x - 10,
              point.y - 10 - i * 20,
              20,
              20
            );
          }
        }
      });
    },
    afterDraw: (chart) => {
      const ctx = chart.ctx;
      const xAxis = chart.scales["x"];
      ctx.save();
      ctx.strokeStyle = "#FFFFFF";
      ctx.lineWidth = 1;
      xAxis.ticks.forEach((tick, index) => {
        const x = xAxis.getPixelForValue(tick.value);
        ctx.beginPath();
        ctx.moveTo(x, chart.chartArea.bottom);
        ctx.lineTo(x, chart.chartArea.bottom - 10); // Adjust the height of the bar
        ctx.stroke();
      });
      ctx.restore();
    },
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "500px" }}>
      <Line data={chartData} options={options} plugins={[customPlugin]} />
    </div>
  );
}

export default BattingGraph;
